import React from "react";
import { Link } from "gatsby";
import { BlogItem } from "api/sdk";

export interface PostGridProps {
  blogItems: BlogItem[];
}

export default (props: PostGridProps) => {
  const { blogItems } = props;

  return (
    <div className="blog-products-grid container--small">
      {blogItems.map((item, index) => {
        let prodClass = "left";
        if (index % 2 !== 0) {
          prodClass = "right";
        }
        return (
          <div key={index} className={`product-row-${prodClass}`}>
            <div className="blog-card">
              <Link to={item.link}>
                <img src={item.image} />
              </Link>
            </div>
            <div className="blog-card-content blog-single-text">
              <p dangerouslySetInnerHTML={{ __html: item.text }} />
              <div className="blog-card-cta">
                <Link to={item.link}>Shop</Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
