import Layout from "layout";
import React, { FC, ReactElement } from "react";
import { BlogPost, PortFolioEntry } from "../api/sdk";
import {
  BasePageContext,
  TemplateHeadProps,
  TemplatePageProps,
} from "utils/types";
import PostGrid from "components/PostGrid";
import SEO from "components/SEO";

export interface BlogDetailPageContext extends BasePageContext {
  blogPost: BlogPost;
}

const htHomeImg = require("images/hillary-taylor-home.jpg").default;

export default (
  props: TemplatePageProps<BlogDetailPageContext>
): ReactElement => {
  const { blogPost, appData } = props.pageContext;

  return (
    <Layout appData={appData} bodyClass="blog-single">
      <div className="container--small spaced-50">
        <div className="title-wrap">
          <h1
            className="with-divider"
            dangerouslySetInnerHTML={{ __html: blogPost.title }}
          ></h1>
        </div>
        <img src={blogPost.headImage} />
        <div
          className="content blog-single-text"
          dangerouslySetInnerHTML={{ __html: blogPost.body }}
        />
      </div>
      <div className="container--medium blog-secondary-image">
        {blogPost.tailImage && <img src={blogPost.tailImage} />}
      </div>

      {blogPost.postItems ? <PostGrid blogItems={blogPost.postItems} /> : ""}

      {/* <div className="next-previous">
        <div className="prev-container">
          {pageContext.prev && (
            <Link className="prev" to={`/blog/${pageContext.prev.slug}`}>
              <span className="arrow-text">previous</span>
              <img src={LeftArrow} alt="" width="75px" />
            </Link>
          )}
        </div>
        <div className="next-container">
          {pageContext.next && (
            <Link className="next" to={`/blog/${pageContext.next.slug}`}>
              <span className="arrow-text">next</span>
              <img src={RightArrow} alt="" width="75px" />
            </Link>
          )}
        </div>
      </div> */}
    </Layout>
  );
};

export const Head = (props: TemplateHeadProps<BlogDetailPageContext>) => {
  const { blogPost } = props.pageContext;

  return (
    <SEO
      title={blogPost.title}
      ogMeta={{
        title: `${blogPost.title} | Hillary Taylor`,
        description: blogPost.body.slice(100) + "...",
        image: blogPost?.headImage ?? htHomeImg,
      }}
    ></SEO>
  );
};
